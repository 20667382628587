@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.client-profile-container {
	// disable text selection highlighting
	user-select: none;
	.client-profile-form {
		.base-info-container {
			margin-bottom: 20px;

			.max-width-170 {
				max-width: 170px;
			}

			// vertical center alignment
			label {
				margin-bottom: auto;
				margin-top: auto;
			}
		}
		.error {
			text-align: left;
			font-size: 11px;
			color: red;
			padding-bottom: 0.5em;
		}
		.required:after {
			content: '*';
			color: red;
		}
		.fingerprint-fields-wrapper {
			margin-bottom: 20px;
			white-space: nowrap;
			// border-bottom: 1px solid black;
		}

		// .lb-t-right {
		//   text-align: right;
		// }

		@include media-breakpoint-up(xs) {
			.lb-t-right {
				text-align: left;
			}
		}

		@include media-breakpoint-up(xl) {
			.lb-t-right {
				text-align: right;
			}
		}
	}

	.action-container {
		text-align: center;
		button {
			min-width: 200px;
			min-height: 60px;

			&:not(:first-child) {
				margin-left: 2rem;
			}
		}
	}
}
