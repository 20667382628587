@import './variables.scss';

.App {
	text-align: center;
	background-color: #e9ecef;

	nav {
		max-height: $nav_bar_height;
	}

	// logo {
	// 	height: 40vmin;
	// }

	// header {
	// 	background-color: #282c34;
	// 	min-height: 100vh;
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// 	justify-content: center;
	// 	font-size: calc(10px + 2vmin);
	// 	color: white;
	// }

	> .container {
		min-height: calc(100vh - #{$nav_bar_height} - #{$footer_height});
	}

	link {
		color: #09d3ac;
	}

	.footer {
		position: relative;
		bottom: 0;
		/* Set the fixed height of the footer here */
		min-height: $footer_height;

		font-size: 0.9rem;
		font-style: italic;
	}
}
