@import url(https://fonts.googleapis.com/css?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.nav-style img{width:60px}@media(max-width: 600px){.nav-style a.btn{font-size:.5rem}}
.overlay{width:100%;height:100%;position:fixed;z-index:100;opacity:.7}.overlay>div{width:5rem;height:5rem;z-index:1;border-width:.5rem}
@media all and (min-width: 480px){.login{padding:60px 0;margin:auto}.login form{margin:0 auto;max-width:320px}}
.home-page code{font-size:120%}
.fingerprintifield{white-space:nowrap;text-align:left;display:grid;grid-template-columns:auto 34% 34%;grid-column-gap:1rem;align-items:center}.fingerprintifield input[type=number]::-webkit-outer-spin-button,.fingerprintifield input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.fingerprintifield input{padding:8px;font-size:16px;text-align:center}.fingerprintifield label{margin-bottom:0}.fingerprintifield .point-error{grid-column:2/3}.fingerprintifield .type-error{grid-column:3/4}.fingerprintifield .suggest-input input{text-align:center}.fingerprintifield{grid-template-columns:10% 45% auto}
.client-profile-container{-webkit-user-select:none;user-select:none}.client-profile-container .client-profile-form .base-info-container{margin-bottom:20px}.client-profile-container .client-profile-form .base-info-container .max-width-170{max-width:170px}.client-profile-container .client-profile-form .base-info-container label{margin-bottom:auto;margin-top:auto}.client-profile-container .client-profile-form .error{text-align:left;font-size:11px;color:red;padding-bottom:.5em}.client-profile-container .client-profile-form .required:after{content:"*";color:red}.client-profile-container .client-profile-form .fingerprint-fields-wrapper{margin-bottom:20px;white-space:nowrap}.client-profile-container .client-profile-form .lb-t-right{text-align:left}@media(min-width: 1200px){.client-profile-container .client-profile-form .lb-t-right{text-align:right}}.client-profile-container .action-container{text-align:center}.client-profile-container .action-container button{min-width:200px;min-height:60px}.client-profile-container .action-container button:not(:first-child){margin-left:2rem}

.App{text-align:center;background-color:#e9ecef}.App nav{max-height:86px}.App>.container{min-height:calc(100vh - 86px - 60px)}.App link{color:#09d3ac}.App .footer{position:relative;bottom:0;min-height:60px;font-size:.9rem;font-style:italic}
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//* User interaction
 * ========================================================================== */body{margin:0;padding:0;font-family:"Comfortaa","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
